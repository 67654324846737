<template>
    <div class='pay-result-page'>
        <div class='pay-result-img'></div>
        <div class='pay-result-text'>您的支付申请已提交，预计30分钟内有还款结果，请前往账单中查看</div>
        <div class="pay-result-btn" @click='goSuccessful'>完成</div>
    </div>
</template>
    
<script>
import { onMounted, toRefs,reactive } from "vue"
import {Toast} from 'vant';
import APP from '../../utils/APP'
import { routerTag} from '../../utils/index'
export default ({
    name: 'Index',
    setup() {
        //还款完成
        const goSuccessful=()=>{
            //跳转到账单页
            window.TDAPP.onEvent("支付完成页-点击完成-进入");
            APP.JUMP_H5(routerTag)
        }
        onMounted(() => {
            APP.SET_TITLE('支付完成')
            APP.IS_SHOW_BACK(false)
        })
        return{
            goSuccessful

        }
    },
})
</script>
<style scoped lang='less' src='./index.less'></style>